// Prod
// export const Domain = 'duv-p.au.auth0.com';
// export const clientId = 'rJkjDNOLM4mFgkeAuDEM6LhQJEmMTCdp';
// export const audience = 'https://duv-p.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'Prod';
// export const connection = 'Duval-Prod';
// export const singup_auth = 'https://duv-p.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://duvalbackend-prod.azurefd.net/api/duval';
// export const adminapi = 'https://duvalbackend-prod.azurefd.net/api/duval/admin';

// //stag
// export const Domain = 'dev-eay5kxke.au.auth0.com';
// export const clientId = 'tUPiMUGwETqtavhuQAcxMboi2qaPlYsn';
// export const audience = 'https://dev-eay5kxke.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'stag';
// export const connection = 'DuVal-DB-Admin';
// export const singup_auth = 'https://dev-eay5kxke.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://duvalstag.azureedge.net/api/duval';
// export const adminapi = 'https://duvalstag.azureedge.net/api/duval/admin';

// dev nz
// export const Domain = 'duvalnzdev.au.auth0.com';
// export const clientId = 'YE9YvxZ8YPLsyWX4SSUV5kfga2Vh2cIp';
// export const audience = 'https://duvalnzdev.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'duvdev';
// export const singup_auth = 'https://duvalnzdev.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://duvalfunctionapp.azureedge.net/api/duval';
// export const adminapi = 'https://duvalfunctionapp.azureedge.net/api/duval/admin';

// dev india
// export const Domain = 'duvdev.au.auth0.com';
// export const clientId = 'fh17Tw1oMaUoDSlysZkyJ6aJH8sKxWIZ';
// export const audience = 'https://duvdev.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'duvdev';
// export const singup_auth = 'https://duvdev.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://duvalstag-backend.azureedge.net/api/duval';
// export const adminapi = 'https://duvalstag-backend.azureedge.net/api/duval/admin';

// company account
// export const Domain = 'duv-comp.au.auth0.com';
// export const clientId = 'VYAZyqfcMO0Nqvc8swbALqWFaargYKDh';
// export const audience = 'https://duv-comp.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'duvdev';
// export const singup_auth = 'https://duvalcompany-backend.azureedge.net/dbconnections/signup';
// export const urlapi = 'https://duvalcompany-backend.azureedge.net/api/duval';
// export const adminapi = 'https://duvalcompany-backend.azureedge.net/api/duval/admin';

// myfarm Dev Environment
// export const Domain = 'myfarm-dev.au.auth0.com';
// export const clientId = 'CYVcrEcDweTbdYdPoX4z2ap2zuYHr3nT';

// export const audience = 'https://myfarm-dev.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'myfarm-dev';
// export const singup_auth = 'https://myfarm-dev.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://24vkgalv5c.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const adminapi = 'https://duvalstag-backend.azureedge.net/api/duval/admin';

// // my firm retails
// export const Domain = 'dev-r914xhfs.au.auth0.com';
// export const clientId = 'EUNRusS6mkyGqSpjxGRAeZK69e5RK6tB';
// export const audience = 'https://dev-r914xhfs.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'myfarm-retail';
// export const singup_auth = 'https://dev-r914xhfs.au.auth0.com/dbconnections/signup';
// export const urlapi = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev/';
// export const adminapi = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com';

// my firm stage
export const Domain = 'myfarm-nz.au.auth0.com';
export const clientId = 'xSI39Ti8kVIsV8Ss9afrxpyTCRH4YJhG';
export const audience = 'https://myfarm-nz.au.auth0.com/api/v2/';
export const CHECK_ENV = 'stag';
export const connection = 'my-farm-nz';
export const singup_auth = 'https://myfarm-nz.au.auth0.com/dbconnections/signup';
export const urlapi = 'https://dk8daj5mgp5cf.cloudfront.net/';
export const adminapi = 'https://dk8daj5mgp5cf.cloudfront.net/';
