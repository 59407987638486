import { Link as RouterLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import ReactFlagsSelect from 'react-flags-select';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';
import Register2 from './Register2';
import logo from '../pages/replica_logo.png';
import rightarrow from '../pages/download-svg.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { Domain, audience, urlapi, clientId } from '../auth/Configuration';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#d5d5d5'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    accessToken,
    user,
    logout
  } = useAuth0();
  const [loader, setLoader] = useState();

  function check() {
    user.profile == 'User' && logout();
  }

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) {}
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          check();
        } else {
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getUserMetadata();
  }, []);
  function handelclick() {
    setLoader(true);
    loginWithRedirect();
    // go("First");
    // childToParent();
  }
  return loader ? (
    <div className="main-loader">
      <div className="loader"></div>
    </div>
  ) : (
    <RootStyle title="Register | Minimal-UI" style={{ fontFamily: 'Roboto' }}>
      {/* <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout> */}

      <div className="ppp main_reg_page">
        <div className="welcome__page register_page">
          <div className="welcome-header">
            <a href="#">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className="wel-come_content">
            <h3>Admin Portal</h3>
            <div className="grid-container">
              <div className="grid-item active"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
              <div className="grid-item"></div>
            </div>

            <div className="fotter-btn registercss">
              {/* <button type="button" className="next" onClick={handelclick}> */}
              {/* <RouterLink to="/register2"> */}
              <button onClick={handelclick}>
                PROCEED TO LOGIN PAGE
                <img src={rightarrow} className="rightarrow" alt="" />
              </button>
              {/* </RouterLink> */}

              {/* <img src={rightarrow} className="rightarrow"  alt=""/>{" "} */}
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
    </RootStyle>
  );
}
